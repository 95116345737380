/**
 * First we will load all of this project's JavaScript dependencies which
 * includes Vue and other libraries. It is a great starting point when
 * building robust, powerful web applications using Vue and Laravel.
 */

require('./bootstrap');
require('jqdoublescroll');
require('jquery-form');
require('rrssb');
require('chart.js');
require('lightbox2');
require('chartjs-plugin-annotation');
require('datatables.net');
require('datatables.net-bs5');
require('datatables.net-responsive');
require('datatables.net-responsive-bs5');
// require('datatables.net-fixedheader');
require('datatables.net-fixedheader-bs5');
require('datatables.net-buttons-bs5');
require('datatables.net-buttons/js/buttons.html5');
require('datatables.net-buttons/js/buttons.flash');
require('datatables.net-buttons/js/buttons.print');
require('datetimepicker');
require('bs-stepper');
require('jquery.payment');
require('jquery-smooth-scroll');


import * as Calendar from '@fullcalendar/core';
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import listPlugin from '@fullcalendar/list';
import interactionPlugin from '@fullcalendar/interaction';
import bgLocale from '@fullcalendar/core/locales/bg';
import enLocale from '@fullcalendar/core/locales/en-gb';
import Hls from 'hls.js';

window.Hls = Hls;
window.FullCalendar = Calendar;
window.dayGridPlugin = dayGridPlugin;
window.timeGridPlugin = timeGridPlugin;
window.listPlugin = listPlugin;
window.interactionPlugin = interactionPlugin;
window.locale_bg = bgLocale;
window.locale_en = enLocale;
window.notify = require('notifyjs-browser');
window.Vue = require('vue');
window.bootstrap = require('bootstrap');

require('./pages/frontend/results');
require('./pages/frontend/organisers');
require('./pages/frontend/registrations');
require('./pages/frontend/checkpoints');
require('./pages/frontend/relay');
require('./pages/frontend/splits');
require('./pages/frontend/chart');
require('./pages/frontend/events');

require('./app-frontend');

Vue.component('example-component', require('./components/ExampleComponent.vue').default);


$(window).on('load', function () {
    $('.post-module').hover(function () {
        $(this).find('.description').stop().animate({
            height: "toggle",
            opacity: "toggle"
        }, 300);
    });
});
