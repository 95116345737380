import { showMessage, initLicenseChange, toggleSubmitDisabled, clearFormErrors,
    setShareButton} from './helpers/functions';
window.showMessage = showMessage;

function getAjaxFormConfig(form, payment = null) {
    var $form = form;
    var $submitButton = $form.find('input[type=submit]');

    toggleSubmitDisabled($submitButton);

    var ajaxFormConf = {
        delegation: true,
        async: false,
        beforeSerialize: function (jqForm, options) {
            window.doSubmit = true;
            clearFormErrors(jqForm[0]);
            toggleSubmitDisabled($submitButton);
        },
        beforeSubmit: function () {
            $submitButton = $form.find('input[type=submit]');
            toggleSubmitDisabled($submitButton);
            return window.doSubmit;
        },
        error: function (data, statusText, xhr, $form) {
            $submitButton = $form.find('input[type=submit]');

            // Form validation error.
            if (422 == data.status) {
                processFormErrors($form, $.parseJSON(data.responseText));
                return;
            }

            toggleSubmitDisabled($submitButton);
            showMessage(lang("whoops", "error"));
        },
        success: function (data, statusText, xhr, $form) {
            var $submitButton = $form.find('input[type=submit]');

            if (data.message) {
                showMessage(data.message, data.status);
            }
            switch (data.status) {
                case 'success':
                    if (data.redirectUrl) {
                        if (data.redirectData) {
                            $.redirectPost(data.redirectUrl, data.redirectData);
                        } else {
                            if (payment) {
                                window.open(data.redirectUrl, "_parent");
                            } else {
                                document.location.href = data.redirectUrl;
                            }
                        }
                    }
                    break;
                case 'error':
                    if (data.messages) {
                        processFormErrors($form, data.messages);
                        toggleSubmitDisabled($submitButton);
                        return;
                    }
                    break;

                default:
                    break;
            }

            toggleSubmitDisabled($submitButton);

        },
        dataType: 'json'
    };

    return ajaxFormConf;
}

$(function () {
    $('form.ajax').on('submit', function (e) {
        e.preventDefault();
        e.stopImmediatePropagation();

        var ajaxFormConf = getAjaxFormConfig($(this));

        ajaxFormConf = getAjaxFormConfig($(this));
        $(this).ajaxSubmit(ajaxFormConf);
    });

    $('#validateTickets').on('submit', function (e) {
        e.preventDefault();
        e.stopImmediatePropagation();
        var valid = false;

        $(".tickets :selected").map(function (i, el) {
            if ($(el).val() !== "0") {
                valid = true;
                return true;
            }
        });

        if (valid) {
            this.submit();
        } else {
            showMessage($(this).data("error"), 'error');
            return false;
        }
    });

    //handles stripe payment form submit
    $('#stripe-payment-form').on('submit', function (e) {
        e.preventDefault();
        e.stopImmediatePropagation();

        stripe.createToken(card).then(function (result) {
            if (result.error) {
                // Inform the user if there was an error.
                var errorElement = document.getElementById('card-errors');
                errorElement.textContent = result.error.message;
            } else {
                // Send the token to your server.
                stripeTokenHandler(result.token);
            }
        });
    });

    $('#paypal-payment-form').on('submit', function (e) {
        e.preventDefault();
        e.stopImmediatePropagation();

        //TODO ??? За кво е това?
        var $ajaxFormConf = getAjaxFormConfig($('#paypal-payment-form'), true);
        $('#paypal-payment-form').ajaxSubmit($ajaxFormConf);
    });

    $('#epay-payment-form').on('submit', function (e) {
        e.preventDefault();
        e.stopImmediatePropagation();

        var $ajaxFormConf = getAjaxFormConfig($('#epay-payment-form'), true);
        $('#epay-payment-form').ajaxSubmit($ajaxFormConf);
    });

    $('#mypos-payment-form').on('submit', function (e) {
        e.preventDefault();
        e.stopImmediatePropagation();

        var $ajaxFormConf = getAjaxFormConfig($('#mypos-payment-form'), true);
        $('#mypos-payment-form').ajaxSubmit($ajaxFormConf);
    });

    function stripeTokenHandler(token) {
        var form = document.getElementById('stripe-payment-form');
        var hiddenInput = document.createElement('input');
        hiddenInput.setAttribute('type', 'hidden');
        hiddenInput.setAttribute('name', 'stripeToken');
        hiddenInput.setAttribute('value', token.id);
        form.appendChild(hiddenInput);

        var $ajaxFormConf = getAjaxFormConfig($('#stripe-payment-form'));
        $('#stripe-payment-form').ajaxSubmit($ajaxFormConf);
    }

    $('#stripe-sca-payment-form').on('submit', function (e) {
        e.preventDefault();
        e.stopImmediatePropagation();

        stripe.createPaymentMethod(
            'card',
            cardElement
        ).then(function (result) {
            if (result.error) {
                var errorElement = document.getElementById('card-errors');
                errorElement.textContent = result.error.message;
            } else {

                stripePaymentMethodHandler(result.paymentMethod);
            }
        });
    });

    function stripePaymentMethodHandler(paymentMethod) {
        var form = document.getElementById('stripe-sca-payment-form');
        var hiddenInput = document.createElement('input');
        hiddenInput.setAttribute('type', 'hidden');
        hiddenInput.setAttribute('name', 'paymentMethod');
        hiddenInput.setAttribute('value', paymentMethod.id);
        form.appendChild(hiddenInput);

        var $ajaxFormConf = getAjaxFormConfig($('#stripe-sca-payment-form'));
        $('#stripe-sca-payment-form').ajaxSubmit($ajaxFormConf);
    }

    $('a').smoothScroll({
        offset: -60
    });

    /* Scroll to top */
    $(window).scroll(function () {
        if ($(this).scrollTop() > 100) {
            $('.totop').fadeIn();
        } else {
            $('.totop').fadeOut();
        }
    });

    $('#organiserHead').on('click', function (e) {
        e.stopImmediatePropagation();
        $('#organiser')[0].scrollIntoView();
    });

    $('#contact_organiser').on('click', function (e) {
        e.preventDefault();
        $('.contact_form').slideToggle();
    });

    $('#mirror_buyer_info').on('click', function (e) {
        $('.ticket_holder_first_name').eq(0).val($('#order_first_name').val());
        $('.ticket_holder_last_name').eq(0).val($('#order_last_name').val());
        $('.ticket_holder_email').eq(0).val($('#order_email').val());
        $('.ticket_holder_phone').eq(0).val($('#order_phone').val());
    });

    $('.card-number').payment('formatCardNumber');
    $('.card-cvc').payment('formatCardCVC');

    // Apply access code here to unlock hidden tickets
    $('#apply_access_code').click(function (e) {
        var $clicked = $(this);
        // Hide any previous errors
        $clicked.closest('.mb-3')
            .removeClass('has-error');

        var url = $clicked.closest('.has-access-codes').data('url');
        var data = {
            'access_code': $('#unlock_code').val(),
            '_token': $('input:hidden[name=_token]').val()
        };

        $.post(url, data, function (response) {
            if (response.status === 'error') {
                // Show any access code errors
                $('#unlock_code').val('');
                $clicked.closest('.mb-3').addClass('has-error');
                showMessage(response.message, "error");
                return;
            }

            //$('#unlock_code').val('');
            $clicked.closest('.has-access-codes').hide();
            showMessage(response.message, "success");
        });
    });

    $('#is_business').click(function (e) {
        var $isBusiness = $(this);
        var isChecked = $isBusiness.hasClass('checked');

        if (isChecked == undefined || isChecked === false) {
            $isBusiness.addClass('checked');
            $('#business_details').removeClass('hidden').show();
        } else {
            $isBusiness.removeClass('checked');
            $('#business_details').addClass('hidden').hide();
        }
    });
});

//TODO да оправим функцията да работи и със select-и
function processFormErrors($form, errors) {
    $.each(errors, function (index, error) {
        var selector = (index.indexOf(".") >= 0) ? '.' + index.replace(/\./g, "\\.") : ':input[name=' + index + ']';
        var $input = $(selector, $form);

        if ($input.prop('type') === 'file') {
            $('#input-' + $input.prop('name')).append('<div class="help-block error">' + error + '</div>')
                .parent()
                .addClass('has-error');
        } else {
            if ($input.parent().hasClass('input-group')) {
                $input = $input.parent();
            }
            $input.after('<div class="help-block error">' + error + '</div>')
                .parent()
                .addClass('has-error');
        }
    });

    var $submitButton = $form.find('input[type=submit]');
    toggleSubmitDisabled($submitButton);
}

$('.timepicker').on('click', function () {
    var picker = $(this).closest('div').find(".hasDatepicker");
    picker.show();
    $("#dtBox").DateTimePicker();
});

function modalListeners() {
    $('.modal-splits-table').DataTable({
        dom: "t",
        paging: false,
        select: true,
        autoWidth: true,
        bSort: false,
        scrollX: true,
        fixedColumns: true,
        // fixedHeader: true,
        // Бъгнат е плъгина и страницата подскаача при повече от една таблица на страница
        language: {
            url: "/assets/javascript/language/" + $('html').attr('lang') + "/language.json"
        },
        columnDefs: [
            {"width": "15%", "targets": 0},
            {"width": "15%", "targets": -1},
        ]
    });

    $('.laps-table').DataTable({
        dom: "tp",
        select: true,
        pageLength: 25,
        autoWidth: true,
        bSort: false,
        scrollX: true,
        fixedColumns: true,
        language: {
            url: "/assets/javascript/language/" + $('html').attr('lang') + "/language.json"
        },
    });

    setShareButton();
}

$.fn.setCountdown = function ($element, seconds) {
    var endTime, mins, msLeft, time, twoMinWarningShown = false;
    function twoDigits(n) {
        return (n <= 9 ? "0" + n : n);
    }

    function updateTimer() {
        msLeft = endTime - (+new Date);
        if (msLeft < 1000) {
            alert(lang("time_run_out"));
            location.reload();
        } else {

            if (msLeft < 120000 && !twoMinWarningShown) {
                showMessage(lang("just_2_minutes"));
                twoMinWarningShown = true;
            }

            time = new Date(msLeft);
            mins = time.getUTCMinutes();
            $element.html('<b>' + mins + ':' + twoDigits(time.getUTCSeconds()) + '</b>');
            setTimeout(updateTimer, time.getUTCMilliseconds() + 500);
        }
    }

    endTime = (+new Date) + 1000 * seconds + 500;
    updateTimer();
}

$.extend(
    {
        redirectPost: function (location, args) {
            var form = '';
            $.each(args, function (key, value) {
                value = value.split('"').join('\"')
                form += '<input type="hidden" name="' + key + '" value="' + value + '">';
            });
            $('<form action="' + location + '" method="POST">' + form + '</form>').appendTo($(document.body)).submit();
        }
    });

let modal_status = false;
$(document.body).on('click', '.loadModal, [data-invoke~=modal]', function (e) {
    var loadUrl = $(this).data('href'),
        cacheResult = $(this).data('cache') === 'on',
        $button = $(this);

    $('.modal').remove();
    $('.modal-backdrop').remove();
    $('html').addClass('working');

    if (!modal_status) {
        modal_status = true
    } else {
        return;
    }
    $.ajax({
        url: loadUrl,
        data: {},
        localCache: cacheResult,
        dataType: 'html',
        success: function (data) {

            $('body').append(data);

            var $modal = $('.modal');

            $modal.modal({
                'backdrop': 'static'
            });

            $modal.modal('show');
            $modal.on('shown.bs.modal', modalListeners);
            $modal.on('hidden.bs.modal', function (e) {
                // window
                location.hash = '';
            });

            $('html').removeClass('working');
            modal_status = false;
        }
    }).done().fail(function (data) {
        $('html').removeClass('working');
        showMessage(lang("whoops_and_error", {"code": data.status, "error": data.statusText}), "error");
        modal_status = false;
    });

    e.preventDefault();
});

$(document).ready(function () {
    $('#terms-of-participation, #ticket-buying-terms').on('change', function () {
        if ($('#terms-of-participation').length) {
            $('#place_order').prop("disabled", (!$('#ticket-buying-terms').prop('checked') || !$('#terms-of-participation').prop('checked')));
        } else {
            $('#place_order').prop("disabled", !$('#ticket-buying-terms').prop('checked'));
        }
    });

    $(document).on('click', '.clickable-row', function (e) {
        window.open($(this).data('href'), '_self');
    });

    setShareButton();
    initLicenseChange();
});
